body {
  margin: 0;
  padding: 0;
}

.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: transparent;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  z-index: 1000;
}

.chat-text {
  border-radius: 8px;
  color: black;
  margin: 0 15px;
  padding: 10px 10px;
  box-shadow: none;
  background-color: #fff;
  font-weight: 600;
  font-size: 16px;
}
